export default {
  title: 'steps.design.title',
  choices: [
    {
      icon: '🪛',
      name: 'steps.design.classic',
      desc: '',
      price: 2000
    },
    {
      icon: '🛠',
      name: 'steps.design.custom',
      desc: '',
      price: 4000
    },
    {
      icon: '🔬',
      name: 'steps.design.complex_custom',
      desc: '',
      price: 8000
    }
  ]
}
