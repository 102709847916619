export default {
  title: 'steps.login.title',
  choices: [
    {
      icon: '✉️',
      name: 'steps.login.email',
      desc: '',
      price: 1500
    },
    {
      icon: '📞',
      name: 'steps.login.phone',
      desc: '',
      price: 1500
    },
    {
      icon: '🌐',
      name: 'steps.login.social_media',
      desc: '',
      price: 2500
    }
  ]
}
