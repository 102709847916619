export default {
  title: 'steps.type.title',
  choices: [
    {
      icon: '🍾',
      name: 'steps.type.mvp',
      desc: '',
      price: 3000
    },
    {
      icon: '🚀',
      name: 'steps.type.one',
      desc: '',
      price: 6000
    },
    {
      icon: '👷',
      name: 'steps.type.two',
      desc: '',
      price: 6000
    }
  ]
}
