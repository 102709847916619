export default {
  title: 'steps.complexity.title',
  choices: [
    {
      icon: '😎',
      name: 'steps.complexity.easy',
      desc: '',
      price: 0
    },
    {
      icon: '🙂',
      name: 'steps.complexity.medium',
      desc: '',
      price: 4000
    },
    {
      icon: '🥵',
      name: 'steps.complexity.hard',
      desc: '',
      price: 8000
    }
  ]
}
