export default {
  title: 'steps.page.title',
  choices: [
    {
      icon: '📄',
      name: 'steps.page.one',
      desc: '',
      price: 3000
    },
    {
      icon: '📑',
      name: 'steps.page.ten',
      desc: '',
      price: 5000
    },
    {
      icon: '🗂',
      name: 'steps.page.twenty',
      desc: '',
      price: 8000
    }
  ]
}
