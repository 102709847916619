import platform from './platform'
import type from './type'
import monetize from './monetize'
import design from './design'
import language from './language'
import login from './login'
import admin from './admin'
import complexity from './complexity'
import page from './page'
import external from './external'

const steps = [
  type,
  platform,
  design,
  language,
  page,
  login,
  complexity,
  admin,
  monetize,
  external
]

export default steps
