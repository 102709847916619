export default {
  'page.steps.backward': 'Backward',
  'page.start.title': 'How much does it cost to create my product ?',
  'page.start.main_action': 'Estimate',
  'page.end.title': 'The estimate cost of your product is',
  'page.end.main_action': 'Contact Us',
  'steps.admin.no': 'No',
  'steps.admin.title': 'Do you want an admin platform ?',
  'steps.admin.yes': 'Yes',
  'steps.complexity.easy': 'Easy',
  'steps.complexity.hard': 'Hard',
  'steps.complexity.medium': 'Medium',
  'steps.complexity.title': 'What is your business complexity ?',
  'steps.design.classic': 'Classic',
  'steps.design.complex_custom': 'Complex Custom',
  'steps.design.custom': 'Custom',
  'steps.design.title': 'How will be the design ?',
  'steps.external.no': 'No',
  'steps.external.title': 'Will you connect with external services ?',
  'steps.external.yes': 'Yes',
  'steps.language.five': '+5',
  'steps.language.one': '1',
  'steps.language.title': 'Available in how many languages ?',
  'steps.language.two': '2-5',
  'steps.login.email': 'Email',
  'steps.login.phone': 'Phone',
  'steps.login.social_media': 'Social Media',
  'steps.login.title': 'Which connection method ?',
  'steps.monetize.advertisement': 'Advertisement',
  'steps.monetize.free': 'Free',
  'steps.monetize.inapp': 'InApp Purchase',
  'steps.monetize.subscription': 'Subscription',
  'steps.monetize.title': 'How will you monetize your app ?',
  'steps.page.one': '1-10',
  'steps.page.ten': '10-20',
  'steps.page.title': 'How many pages ?',
  'steps.page.twenty': '+20',
  'steps.platform.desktop': 'Desktop',
  'steps.platform.mobile': 'Mobile',
  'steps.platform.title': 'On which platform do you want your app ?',
  'steps.platform.web': 'Web',
  'steps.type.mvp': 'Minimum Viable Product',
  'steps.type.one': 'First Version',
  'steps.type.title': 'What stage this is for your product ?',
  'steps.type.two': 'Rebuild'
}
