export default {
  title: 'steps.monetize.title',
  choices: [
    {
      icon: '🎁',
      name: 'steps.monetize.free',
      desc: '',
      price: 0
    },
    {
      icon: '📢',
      name: 'steps.monetize.advertisement',
      desc: '',
      price: 2000
    },
    {
      icon: '🔄',
      name: 'steps.monetize.subscription',
      desc: '',
      price: 4000
    },
    {
      icon: '💶',
      name: 'steps.monetize.inapp',
      desc: '',
      price: 4000
    }
  ]
}
