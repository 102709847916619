export default {
  title: 'steps.platform.title',
  choices: [
    {
      icon: '🌐',
      name: 'steps.platform.web',
      desc: '',
      price: 0
    },
    {
      icon: '📱',
      name: 'steps.platform.mobile',
      desc: '',
      price: 3000
    },
    {
      icon: '💻',
      name: 'steps.platform.desktop',
      desc: '',
      price: 0
    }
  ]
}
