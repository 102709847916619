export default {
  title: 'steps.external.title',
  choices: [
    {
      icon: '❌',
      name: 'steps.external.no',
      desc: '',
      price: 0
    },
    {
      icon: '✅',
      name: 'steps.external.yes',
      desc: '',
      price: 3000
    }
  ]
}
