export default {
  'page.steps.backward': 'Retour',
  'page.start.title': 'Combien coûte la création de mon produit ?',
  'page.start.main_action': 'Estimation',
  'page.end.title': 'Le coût estimé de votre produit est de',
  'page.end.main_action': 'Nous contacter',
  'steps.admin.no': 'Non',
  'steps.admin.title': "Voulez-vous une plateforme d'administration ?",
  'steps.admin.yes': 'Oui',
  'steps.complexity.easy': 'Facile',
  'steps.complexity.hard': 'Hard',
  'steps.complexity.medium': 'Moyen',
  'steps.complexity.title': 'Quelle est la complexité de votre entreprise ?',
  'steps.design.classic': 'Classique',
  'steps.design.complex_custom': 'Complexe sur mesure',
  'steps.design.custom': 'Personnalisé',
  'steps.design.title': 'Comment sera le design ?',
  'steps.external.no': 'Non',
  'steps.external.title': 'Allez-vous vous connecter à des services externes ?',
  'steps.external.yes': 'Oui',
  'steps.language.five': '+5',
  'steps.language.one': '1',
  'steps.language.title': 'Disponible dans combien de langues ?',
  'steps.language.two': '2-5',
  'steps.login.email': 'Courriel',
  'steps.login.phone': 'Téléphone',
  'steps.login.social_media': 'Médias sociaux',
  'steps.login.title': 'Quelle méthode de connexion ?',
  'steps.monetize.advertisement': 'Advertisement',
  'steps.monetize.free': 'Gratuit',
  'steps.monetize.inapp': "Achat dans l'application",
  'steps.monetize.subscription': 'Abonnement',
  'steps.monetize.title': 'Comment allez-vous monétiser votre application ?',
  'steps.page.one': '1-10',
  'steps.page.ten': '10-20',
  'steps.page.title': 'Combien de pages ?',
  'steps.page.twenty': '+20',
  'steps.platform.desktop': 'Bureau',
  'steps.platform.mobile': 'Mobile',
  'steps.platform.title': 'Sur quelle plateforme voulez-vous votre application ?',
  'steps.platform.web': 'Web',
  'steps.type.mvp': 'Produit minimum viable',
  'steps.type.one': 'Première version',
  'steps.type.title': 'A quel stade se trouve votre produit ?',
  'steps.type.two': 'Reconstruire'
}
