export default {
  title: 'steps.admin.title',
  choices: [
    {
      icon: '❌',
      name: 'steps.admin.no',
      desc: '',
      price: 0
    },
    {
      icon: '✅',
      name: 'steps.admin.yes',
      desc: '',
      price: 5000
    }
  ]
}
