export default {
  title: 'steps.language.title',
  choices: [
    {
      icon: '🇬🇧',
      name: 'steps.language.one',
      desc: '',
      price: 0
    },
    {
      icon: '🗾',
      name: 'steps.language.two',
      desc: '',
      price: 1000
    },
    {
      icon: '🌍',
      name: 'steps.language.five',
      desc: '',
      price: 2000
    }
  ]
}
