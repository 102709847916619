import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { IntlProvider, FormattedMessage } from 'react-intl'

import '@/css/main.css'

import logo from '@/images/logo.png'

import { steps, translations } from '@/data'

const nlanguage = typeof window !== 'undefined' ? (window.navigator.language.split(/[-_]/)[0] || 'en') : 'en'
const language = Object.keys(translations).includes(nlanguage) ? nlanguage : 'en'

const Generator = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [price, setPrice] = useState([])
  const [start, setStart] = useState(true)

  const iChoosed = (choice) => {
    setPrice(prevPrice => [...prevPrice, choice.price])
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const backward = () => {
    setPrice(prevPrice => prevPrice.slice(0, -1))
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <Fragment>
      <Helmet>
        <title>
          Khufu - Quotes Generator
        </title>
      </Helmet>
      <Link href='https://khufu.io' target='_blank' referrer='no-referrer'>
        <img className='logo' src={logo} alt='logo' />
      </Link>
      <IntlProvider messages={translations[language]} locale={language} defaultLocale='en'>
        <Container>
          {start
            ? <div className='startContainer'>
              <div className='startTitle'>
                <span><FormattedMessage id='page.start.title' /></span>
              </div>
              <Button variant='primary' onClick={() => setStart(false)}><FormattedMessage id='page.start.main_action' /></Button>
          </div>
            : steps.length > activeStep
              ? <Row>
            <Col xs={12}>
              <h1 className='question'><FormattedMessage id={steps[activeStep].title} /></h1>
            </Col>
            <Col xs={12} xl={(12 - steps[activeStep].choices.length * 4) / 2} />
            {steps[activeStep].choices.map((choice, index) => (
              <Col className='choice' key={index} xs={12} md={6} xl={12 / steps[activeStep].choices.length < 4 ? 12 / steps[activeStep].choices.length : 4}>
                <Row onClick={() => iChoosed(choice)}>
                  <Col xs={12}>
                    <div className='icon'>{choice.icon}</div>
                    <div className='name'>{<FormattedMessage id={choice.name} />}</div>
                    <div className='desc'>{choice.desc}</div>
                  </Col>
                </Row>
              </Col>
            ))}
            {activeStep > 0
              ? <Col xs={12}>
              <Button variant='warning' size='lg' onClick={backward}>{'< '}<FormattedMessage id='page.steps.backward' /></Button>
            </Col>
              : ''}
          </Row>
              : <div className='priceContainer'>
                <div className='priceTitle'>
                  <span><FormattedMessage id='page.end.title' /></span>
                </div>
                <div className='price'>{price.reduce((a, b) => a + b).toLocaleString(language, {
                  style: 'currency',
                  currency: ['fr', 'es'].includes(language) ? 'EUR' : 'USD',
                  maximumFractionDigits: 0
                })}</div>
                <Link href='https://khufu.io/contact' target='_blank' referrer='no-referrer'><FormattedMessage id='page.end.main_action' /></Link>
            </div>}
        </Container>
      </IntlProvider>
    </Fragment>
  )
}

export default Generator
