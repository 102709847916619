export default {
  'page.steps.backward': 'Regreso',
  'page.start.title': '¿Cuánto cuesta crear mi producto?',
  'page.start.main_action': 'Estimación',
  'page.end.title': 'El coste estimado de su producto es',
  'page.end.main_action': 'Contacto',
  'steps.admin.no': 'No',
  'steps.admin.title': '¿Quiere una plataforma de administración?',
  'steps.admin.yes': 'Si',
  'steps.complexity.easy': 'Fácil',
  'steps.complexity.hard': 'Duro',
  'steps.complexity.medium': 'Medio',
  'steps.complexity.title': '¿Cuál es la complejidad de su negocio?',
  'steps.design.classic': 'Clásico',
  'steps.design.complex_custom': 'Complejo personalizado',
  'steps.design.custom': 'Personalizado',
  'steps.design.title': '¿Cómo será el diseño?',
  'steps.external.no': 'No',
  'steps.external.title': '¿Se conectará con servicios externos?',
  'steps.external.yes': 'Si',
  'steps.language.five': '+5',
  'steps.language.one': '1',
  'steps.language.title': '¿En cuántos idiomas está disponible?',
  'steps.language.two': '2-5',
  'steps.login.email': 'Envíe un correo electrónico a',
  'steps.login.phone': 'Teléfono',
  'steps.login.social_media': 'Social Media',
  'steps.login.title': '¿Qué método de conexión?',
  'steps.monetize.advertisement': 'Publicidad',
  'steps.monetize.free': 'Gratis',
  'steps.monetize.inapp': 'Compra en la aplicación',
  'steps.monetize.subscription': 'Suscripción',
  'steps.monetize.title': '¿Cómo va a monetizar su aplicación?',
  'steps.page.one': '1-10',
  'steps.page.ten': '10-20',
  'steps.page.title': '¿Cuántas páginas?',
  'steps.page.twenty': '+20',
  'steps.platform.desktop': 'Escritorio',
  'steps.platform.mobile': 'Móviles',
  'steps.platform.title': '¿En qué plataforma quiere su aplicación?',
  'steps.platform.web': 'Web',
  'steps.type.mvp': 'Producto mínimo viable',
  'steps.type.one': 'Primera versión',
  'steps.type.title': '¿En qué fase se encuentra su producto?',
  'steps.type.two': 'Reconstruir'
}
